<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title> LOGIN </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <Form v-slot="{ meta }" @submit="noop()">
        <ion-list>
          <Field
            name="email"
            v-slot="{ field, errorMessage }"
            rules="required|email"
            v-model="email"
          >
            <ion-item lines="full">
              <ion-label position="stacked">E-mail</ion-label>
              <ion-input
                :readonly="$store.state.user.token"
                name="email"
                type="text"
                placeholder="jean@example.com"
                required
                v-model="email"
                v-bind="field"
                inputmode="email"
              ></ion-input>
              <ion-text
                class="error ion-padding"
                color="danger"
                v-if="errorMessage"
                >{{ errorMessage }}</ion-text
              >
            </ion-item>
          </Field>
          <Field
            name="password"
            v-slot="{ field, errorMessage }"
            rules="required"
            v-model="password"
            v-if="!$store.state.user.token"
          >
            <ion-item>
              <ion-label position="stacked">Mot de passe</ion-label>
              <ion-input
                name="password"
                type="password"
                autocomplete="current-password"
                v-bind="field"
                required
                v-model="password"
              ></ion-input>
              <ion-text
                class="error ion-padding"
                color="danger"
                v-if="errorMessage"
                >{{ errorMessage }}</ion-text
              >
              <ion-text
                class="error ion-padding"
                color="danger"
                v-if="loginError"
                >{{ loginError }}</ion-text
              >
            </ion-item>
          </Field>
          <ion-row v-if="!$store.state.user.token">
            <ion-col class="ion-padding">
              <ion-button
                type="submit"
                expand="block"
                size="default"
                :disabled="!meta.valid"
                @click="doLogin()"
                >Login</ion-button
              >
            </ion-col>
          </ion-row>
          <Field
            name="company"
            v-slot="{ field, errorMessage }"
            rules="required"
            v-model="company"
            v-if="
              $store.state.user.token && $store.state.loginConfig.length > 1
            "
          >
            <ion-item>
              <ion-label position="stacked">Société</ion-label>
              <ion-select
                name="company"
                v-bind="field"
                v-model="company"
                interface="popover"
              >
                <ion-select-option
                  v-for="c in $store.state.loginConfig"
                  :value="c.org"
                  :key="c.org"
                >
                  {{ c.orgName }}
                </ion-select-option>
              </ion-select>
              <ion-text color="danger" v-if="errorMessage">{{
                errorMessage
              }}</ion-text>
            </ion-item>
          </Field>

          <Field
            name="group"
            v-slot="{ field, errorMessage }"
            rules="required"
            v-model="group"
            v-if="$store.state.user.token"
          >
            <ion-item>
              <ion-label position="stacked">Groupe</ion-label>
              <ion-input
                name="group"
                v-bind="field"
                type="text"
                placeholder="Agence Y"
                required
                v-model="group"
              ></ion-input>
              <ion-text color="danger" v-if="errorMessage">{{
                errorMessage
              }}</ion-text>
            </ion-item>
          </Field>
          <ion-item v-if="$store.state.user.token">
            <ion-label>
              <ion-button
                type="submit"
                expand="block"
                size="default"
                :disabled="!meta.valid"
                @click="setConfig()"
                >Continuer</ion-button
              >
            </ion-label>
          </ion-item>
        </ion-list>
      </Form>

      <div class="ion-padding">
        <p>Pas de compte? Vous pouvez en créer un gratuitement.</p>
        <p>
          <a
            class="button button-positive"
            :href="`${API_PLATFORM}/accounts/signup/?next=/platform/redirect/xsample`"
            >Créer un compte</a
          >
        </p>
        <p>
          <a :href="`${API_PLATFORM}/accounts/password/reset/`"
            >Mot de passe oublié?</a
          >
        </p>
      </div>

      <div class="ion-padding">
        <div class="eula">
          <h4>Limite de responsabilité</h4>
          En utilisant XSample, la société Xtenso SàRL ne peut en aucun cas être
          tenue pour responsable de l’utilisation du logiciel, des résultats
          qu’il fournit et de l’exploitation de ceux-ci. XSample est propriété
          exclusive de Xtenso SàRL. Xtenso SàRL offre un droit d’utilisation de
          XSample aux utilisateurs qu’elle peut interrompre à tout instant sans
          aucune information préalable transmise à ces derniers. Les données des
          utilisateurs (adresses emails, comptes et logs sont envoyés sur un
          serveur sécurisé appartenant à Xtenso SàRL).
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { ref, watchEffect } from "vue";
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import { Field, Form } from "vee-validate";

import { defineComponent } from "vue";

import { API_PLATFORM, useStore } from "../../store";

export default defineComponent({
  name: "UserLogin",
  setup() {
    const store = useStore();
    const password = ref("");
    const loginError = ref("");

    const email = ref("");
    const group = ref("");
    const company = ref("");

    watchEffect(() => {
      if (store.state.user.email) {
        email.value = store.state.user.email;
      }
      if (store.state.user.group) {
        group.value = store.state.user.group;
      }
      if (store.state.user.company) {
        company.value = store.state.user.company;
      }
    });

    function doLogin() {
      store
        .dispatch("doLogin", {
          email: email.value,
          password: password.value,
        })
        .then(() => {
          loginError.value = "";
        })
        .catch(() => {
          loginError.value = "Identifiants incorrects";
        });
    }

    async function setConfig() {
      await store.dispatch("setConfig", {
        company: company.value,
        group: group.value,
      });
      modalController.dismiss();
    }

    return {
      modalController,
      API_PLATFORM,
      email,
      password,
      group,
      company,
      loginError,
      doLogin,
      setConfig,
      noop() {
        //nothing
      },
    };
  },
  components: {
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonCol,
    IonRow,
    Field,
    Form,
  },
});
</script>

<style scoped>
.eula {
  font-size: 80%;
}
</style>