import { InjectionKey } from "vue";
import { alertController } from "@ionic/vue";
import { Store, useStore as baseUseStore, createStore } from "vuex";
import localForage from "localforage";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_ACTIVITYDATA,
  ADD_LOG,
  REPLACE_LOG,
  ADD_RESOURCE,
  DELETE_ACTIVITY,
  DELETE_LOG,
  DELETE_RESOURCE,
  LOG_SYNCED,
  SET_CURRENT_RESOURCE_ID,
  SET_LOGIN_CONFIG,
  SET_LOGS,
  SET_RESOURCES,
  SET_USER,
  SET_USER_PARTIAL,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_PARTIAL,
  UPDATE_RESOURCE,
} from "./mutations";
import {
  Activity,
  ActivityData,
  Config,
  Log,
  LogData,
  Resource,
  State,
  User,
} from "./models";
import { showCollecteActivityData, showUserLogin } from "@/services/modals";

export const API = "https://xsample.xtenso.com";
export const API_PLATFORM = "https://app.xtenso.com";

const KEY_LOCAL_RESOURCES = "resources.list";
const KEY_LOCAL_CURRENT_ID = "currentResourceId";
const KEY_LOCAL_USER = "user";
const KEY_LOCAL_LOGS = "logs";

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state() {
    return {
      currentResourceId: "",
      user: {
        group: "principal",
        displayConfirm: false,
        stayOnResource: false,
        displayOther: false,
        config: {
          app: "xsample",
        },
      },
      resources: [],
      logs: [],
      loginConfig: [],
      ionRouter: null,
    };
  },
  getters: {
    resourceNames(state) {
      return state.resources.map((resource) => resource.name);
    },
  },
  mutations: {
    [SET_USER](state, user: User) {
      state.user = user;
    },
    [SET_USER_PARTIAL](state, user: User) {
      state.user = Object.assign(
        {},
        JSON.parse(JSON.stringify(state.user)),
        user
      );
    },
    [ADD_RESOURCE](state, resource: Resource) {
      state.resources.push(resource);
    },
    [ADD_ACTIVITYDATA](
      state,
      {
        activity,
        activityData,
        editName,
      }: { activity: Activity; activityData: ActivityData; editName?: string }
    ) {
      if (!activity.data) {
        activity.data = [];
      }
      if (editName) {
        activity.data.splice(
          activity.data.findIndex((d) => d.name === editName),
          1,
          activityData
        );
      } else {
        activity.data.push(activityData);
      }
    },
    [UPDATE_RESOURCE](state, resource: Resource) {
      const idx = state.resources.findIndex((r) => r.id === resource.id);
      if (idx > -1) {
        state.resources[idx] = resource;
      }
    },
    [DELETE_RESOURCE](state, resource: Resource) {
      state.resources = state.resources.filter((r) => r.id !== resource.id);
    },
    [SET_RESOURCES](state, resources: any[]) {
      state.resources = resources;
    },
    [UPDATE_ACTIVITY](
      state,
      { resource, activity }: { resource: Resource; activity: Activity }
    ) {
      const index = resource.activities.findIndex(
        (a) => a.name === activity.name
      );
      if (index > -1) {
        resource.activities[index] = activity;
      }
    },
    [UPDATE_ACTIVITY_PARTIAL](
      state,
      { activity, data }: { activity: Activity; data: any }
    ) {
      Object.assign(activity, data);
    },
    [DELETE_ACTIVITY](
      state,
      { resource, activity }: { resource: Resource; activity: Activity }
    ) {
      const index = resource.activities.findIndex(
        (a) => a.name === activity.name
      );
      if (index > -1) {
        resource.activities.splice(index, 1);
      }
    },
    [SET_CURRENT_RESOURCE_ID](state, id: string) {
      state.currentResourceId = id;
    },
    [ADD_LOG](state, log: Log) {
      state.logs.push(log);
    },
    [REPLACE_LOG](state, log: Log) {
      const index = state.logs.findIndex((l) => l.id === log.id);
      if (index > -1) {
        state.logs[index] = log;
      }
    },
    [DELETE_LOG](state, log: Log) {
      const idx = state.logs.indexOf(log);
      if (idx > -1) {
        state.logs.splice(idx, 1);
      }
    },
    [LOG_SYNCED](state, log) {
      log.s = 1;
    },
    [SET_LOGS](state, logs: any[]) {
      state.logs = logs;
    },
    [SET_LOGIN_CONFIG](state, config: Config[]) {
      state.loginConfig = config;
    },
    // workaround to inject only accessible in setup
    setIonicRouter(state, ionRouter) {
      state.ionRouter = ionRouter;
    },
  },
  actions: {
    /* OPTIONS AND LOCAL DATA */
    async loadLocalData({ commit, dispatch }) {
      const resources = (await localForage.getItem(KEY_LOCAL_RESOURCES)) || [];
      const currentResourceId = await localForage.getItem(KEY_LOCAL_CURRENT_ID);
      const data: User | null = await localForage.getItem(KEY_LOCAL_USER);
      commit(SET_RESOURCES, resources);
      commit(SET_CURRENT_RESOURCE_ID, currentResourceId);
      if (
        data &&
        data.hasOwnProperty("company") &&
        data.hasOwnProperty("group") &&
        data.hasOwnProperty("email")
      ) {
        commit(SET_USER_PARTIAL, data);
        dispatch("setConfig", { company: data.company, group: data.group });
      }
      const logs = (await localForage.getItem(KEY_LOCAL_LOGS)) || [];
      commit(SET_LOGS, logs);
      if (!store.state.user.token) {
        // try auto login via platform cookie
        await dispatch("platformLogin");
        showUserLogin();
      }
    },
    async getConfig({ commit, state }, org: string) {
      // available orgs for this user
      const data: Config = {
        app: "xsample",
      };
      if (org) {
        data.org = org;
      }
      const response = await axios.post(`${API_PLATFORM}/api/config`, data, {
        headers: {
          Authorization: "Token " + state.user.token,
        },
      });
      commit(SET_LOGIN_CONFIG, response.data);
      if (response.data.length > 0) {
        commit(SET_USER_PARTIAL, { company: response.data[0].org });
      }
      return response.data;
    },
    async setConfig({ commit, dispatch }, { company, group }) {
      const configs = await this.dispatch("getConfig", company);
      commit(SET_USER_PARTIAL, { company, group, config: configs[0] });
      dispatch("resendLog");
      if (store.state.currentResourceId) {
        dispatch("goToResourceId", store.state.currentResourceId);
      }
      return dispatch("saveOptions");
    },
    async setCurrentResourceId({ commit }, id: string) {
      commit(SET_CURRENT_RESOURCE_ID, id);
      await localForage.setItem(KEY_LOCAL_CURRENT_ID, id);
    },
    async saveOptions({ commit, state, dispatch }) {
      // Try loading remote template
      try {
        const response = await axios.post(`${API}/api/templates`, {
          token: state.user.token,
          company: state.user.company,
          group: state.user.group,
        });
        commit(SET_RESOURCES, response.data);
        dispatch("saveResources", false);
      } catch (e) {
        console.log(e);
      }
      localForage.setItem(
        KEY_LOCAL_USER,
        JSON.parse(JSON.stringify(state.user))
      );
    },
    async doLogin({ commit, dispatch }, { email, password }) {
      const response = await axios.post(`${API_PLATFORM}/api/token-auth`, {
        username: email,
        password,
      });
      commit(SET_USER_PARTIAL, {
        token: response.data.token,
        email,
      });
      dispatch("getConfig");
    },
    async platformLogin({ commit, dispatch }) {
      try {
        const response = await axios.get(`${API_PLATFORM}/api/token`, {
          withCredentials: true,
        });
        commit(SET_USER_PARTIAL, {
          token: response.data.token,
          email: response.data.email,
        });
        dispatch("getConfig");
      } catch (e) {
        //
      }
      return;
    },
    async clearUser({ commit, dispatch }) {
      localForage.removeItem("user");
      commit(SET_USER_PARTIAL, { token: null });
      dispatch("goToHome");
      showUserLogin();
    },
    async clearLogs({ commit }) {
      commit(SET_LOGS, []);
      await localForage.setItem(KEY_LOCAL_LOGS, []);
    },
    /*

     RESOURCES

     */
    async createResource({ commit, dispatch }, name) {
      const resource: Resource = {
        id: uuidv4(),
        name,
        activities: [],
      };
      commit(ADD_RESOURCE, resource);
      dispatch("saveResources", true);
      dispatch("goToResourceId", resource.id);
    },
    async saveResources({ state }, upload) {
      // TODO refactor vuex, split push only remove need for upload bool
      localForage.setItem(
        KEY_LOCAL_RESOURCES,
        JSON.parse(JSON.stringify(state.resources))
      );

      // try to save copy on server
      if (upload) {
        axios.post(`${API}/api/templates/save`, {
          token: state.user.token,
          company: state.user.company,
          group: state.user.group,
          // eslint-disable-next-line @typescript-eslint/camelcase
          user_timestamp: new Date(),
          template: JSON.parse(JSON.stringify(state.resources)),
        });
      }
    },
    async updateResource({ commit, dispatch }, resource: Resource) {
      commit(UPDATE_RESOURCE, resource);
      dispatch("saveResources", true);
    },
    async setResources({ commit, dispatch }, resources: Resource[]) {
      commit(SET_RESOURCES, resources);
      dispatch("saveResources", true);
    },
    async deleteResource({ commit, dispatch }, resource: Resource) {
      commit(DELETE_RESOURCE, resource);
      dispatch("saveResources", true);
      dispatch("goToHome");
    },
    /*

    ACTIVITY

    */
    async addActivity(
      { dispatch },
      { resource, activity }: { resource: Resource; activity: Activity }
    ) {
      const newResource = Object.assign({}, resource);
      newResource.activities = resource.activities.concat(activity);
      dispatch("updateResource", newResource);
    },
    async updateActivity(
      { commit, dispatch },
      { resource, activity }: { resource: Resource; activity: Activity }
    ) {
      commit(UPDATE_ACTIVITY, { resource, activity });
      dispatch("saveResources", true);
    },
    async updateActivityPartial(
      { commit, dispatch },
      { activity, data }: { activity: Activity; data: any }
    ) {
      commit(UPDATE_ACTIVITY_PARTIAL, { activity, data });
      dispatch("saveResources", true);
    },
    async addActivityData(
      { commit, dispatch },
      {
        activity,
        activityData,
        editName,
      }: { activity: Activity; activityData: ActivityData; editName?: string }
    ) {
      commit(ADD_ACTIVITYDATA, {
        activity,
        activityData,
        editName,
      });
      dispatch("saveResources", true);
    },
    async deleteActivity(
      { commit, dispatch },
      { resource, activity }: { resource: Resource; activity: Activity }
    ) {
      commit(DELETE_ACTIVITY, { resource, activity });
      dispatch("saveResources", true);
      dispatch("goToResourceId", resource.id);
    },
    /*

    LOGS

    */
    async logActivity(
      { dispatch },
      { resource, activity }: { resource: Resource; activity: Activity }
    ) {
      if (activity.data && activity.data.length > 0) {
        showCollecteActivityData(resource, activity);
      } else {
        dispatch("createActivityLog", { resource, activity, data: null });
      }
    },
    async createActivityLog(
      { commit, state, dispatch },
      {
        resource,
        activity,
        data,
      }: { resource: Resource; activity: Activity; data: LogData | undefined }
    ) {
      const log: Log = {
        id: uuidv4(),
        resourceId: resource.id,
        resourceName: resource.name,
        activity: activity.name,
        type: activity.type,
        timestamp:
          data && data.hasOwnProperty("timestampStart")
            ? data.timestampStart
            : new Date(),
        s: 0, // synced flag
        data: data,
      };
      commit(ADD_LOG, log);
      localForage.setItem(
        KEY_LOCAL_LOGS,
        JSON.parse(JSON.stringify(state.logs))
      );
      dispatch("sendLog", log);
      dispatch("handleNextItemLogic");
      return log;
    },
    async sendLog({ commit, state }, log: Log) {
      const logsend = JSON.parse(JSON.stringify(log));
      logsend.company = state.user.company;
      logsend.group = state.user.group;
      logsend.email = state.user.email;
      logsend.token = state.user.token;
      return axios.post(`${API}/api/log`, logsend).then(() => {
        commit(LOG_SYNCED, log);
        localForage.setItem(
          KEY_LOCAL_LOGS,
          JSON.parse(JSON.stringify(state.logs))
        );
      });
    },
    async resendLog({ dispatch, state }) {
      const logs = state.logs.filter((log) => log.s === 0);
      for (const log of logs) {
        try {
          await dispatch("sendLog", log);
        } catch (e) {
          //
        }
      }
    },
    async handleNextItemLogic({ state, dispatch }) {
      if (state.user.displayConfirm) {
        const alert = await alertController.create({
          message: "Mesure enregistrée",
          buttons: [
            {
              text: "OK, même resource",
              role: "cancel",
            },
            {
              text: "Resource suivante",
              handler: () => {
                dispatch("nextResource");
              },
            },
          ],
        });
        alert.present();
      } else {
        // mode guichet
        if (state.resources.length === 1 || state.user.stayOnResource) {
          // stay on resource
        } else {
          // mode suivant auto
          dispatch("nextResource");
        }
      }
    },
    async loadRemoteLogs({ state }, offset) {
      const response = await axios.get(
        `${API}/api/log?company=${state.user.company}&group=${state.user.group}&size=50&offset=${offset}`,
        {
          headers: {
            authorization: "Token: " + state.user.token,
          },
        }
      );
      return response.data;
    },
    async updateLogData({ commit, state, dispatch }, log: Log) {
      log.s = 0;
      log.update = 1;
      commit(REPLACE_LOG, log);
      localForage.setItem(
        KEY_LOCAL_LOGS,
        JSON.parse(JSON.stringify(state.logs))
      );
      dispatch("sendLog", log);
    },
    deleteLog({ commit, state }, log: Log) {
      commit(DELETE_LOG, log);
      axios.post(`${API}/api/log/delete`, {
        id: log.id,
        company: state.user.company,
        group: state.user.group,
        email: state.user.email,
        token: state.user.token,
      });
      localForage.setItem(
        KEY_LOCAL_LOGS,
        JSON.parse(JSON.stringify(state.logs))
      );
    },
    async exportLogs({ state }) {
      await axios.post(`${API}/api/export`, {
        company: state.user.company,
        group: state.user.group,
        email: state.user.email,
        logs: state.logs,
      });
      const alert = await alertController.create({
        header: "Export",
        message: "E-mail envoyé",
        buttons: ["OK"],
      });
      await alert.present();
    },
    /*

  NAVIGATION

  */
    async goToHome({ state }) {
      state.ionRouter.navigate({
        routerDirection: "root",
        routerLink: `/`,
      });
    },
    async goToResourceId({ state }, resourceId: string) {
      state.ionRouter.navigate({
        routerDirection: "root",
        routerLink: `/resource/${resourceId}`,
      });
    },
    async nextResource({ state, dispatch }) {
      const resourceId = state.ionRouter.getCurrentRouteInfo().params.id;
      let index = state.resources.findIndex((r) => r.id === resourceId);
      if (index < 0) {
        dispatch("goToHome");
      } else if (index < state.resources.length - 1) {
        index += 1;
      } else {
        index = 0;
      }
      dispatch("goToResourceId", state.resources[index].id);
    },
  },
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
