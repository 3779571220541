
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonDatetime,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

import {
  PropType,
  defineComponent,
  ref,
  watchEffect,
} from "vue";
import { useStore } from "@/store";
import { Activity, ActivityDataType, LogData, Resource } from "@/store/models";

export default defineComponent({
  name: "CollecteData",
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const logData = ref<LogData>({timestampStart: new Date()});

    async function createActivityLog() {
      const data = logData.value;
      data.timestampEnd = new Date();
      data.duration = Math.ceil((data.timestampEnd.getTime() - data.timestampStart.getTime()) / 1000)
      console.log(data);
      await store.dispatch("createActivityLog", {
        resource: props.resource,
        activity: props.activity,
        data: logData,
      });
      modalController.dismiss();
    }

    watchEffect(() => {
      logData.value = (props.activity.data || []).reduce((obj: any, input) => {
            obj[input.name] = undefined;
            return obj;
          }, {
            timestampStart: new Date(),
          });
    });

    return {
      modalController,
      logData,
      createActivityLog,
      ActivityDataType,
      makeOptions(options: string) {
        return options.split(",").map(option => option.trim());
      }
    };
  },
  components: {
    Field,
    Form,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    IonDatetime,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
  },
});
