<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="modalController.dismiss()"
            >Annuler</ion-button
          >
        </ion-buttons>
        <ion-title> {{ activity.name }}: données supplémentaires</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding">
      <Form v-slot="{ meta }" @submit="createActivityLog()">
        <ion-list v-if="activity.data">
          <div v-for="input in activity.data" :key="input.name">
            <Field
              v-if="input.type === ActivityDataType.TEXT"
              :name="input.name"
              v-slot="{ field, errorMessage, meta }"
              v-model="logData[input.name]"
              :rules="{ required: input.required }"
            >
              <ion-item :class="{required: input.required && !meta.valid }">
                <ion-label position="floating">{{ input.name }}</ion-label>
                <ion-input
                  v-bind="field"
                  :name="input.name"
                  v-model="logData[input.name]"
                  :required="input.required"
                  type="text"
                ></ion-input>
                <ion-text color="danger" slot="end" v-if="errorMessage">{{
                  errorMessage
                }}</ion-text>
              </ion-item>
            </Field>

            <Field
              v-if="input.type === ActivityDataType.NUMBER"
              :name="input.name"
              v-slot="{ field, errorMessage, meta }"
              v-model="logData[input.name]"
              :rules="{ required: input.required }"
            >
              <ion-item :class="{required: input.required && !meta.valid }">
                <ion-label position="floating">{{ input.name }}</ion-label>
                <ion-input
                  v-bind="field"
                  :name="input.name"
                  v-model="logData[input.name]"
                  :required="input.required"
                  type="number"
                ></ion-input>
                <ion-text color="danger" slot="end" v-if="errorMessage">{{
                  errorMessage
                }}</ion-text>
              </ion-item>
            </Field>

            <Field
              v-if="input.type === ActivityDataType.DATE"
              :name="input.name"
              v-slot="{ field, errorMessage, meta }"
              v-model="logData[input.name]"
              :rules="{ required: input.required }"
            >
              <ion-item :class="{required: input.required && !meta.valid }">
                <ion-label position="floating">{{ input.name }}</ion-label>
                <ion-datetime
                  v-bind="field"
                  :name="input.name"
                  v-model="logData[input.name]"
                  :required="input.required"
                  display-format="YYYY-MM-DD"
                ></ion-datetime>
                <ion-text color="danger" slot="end" v-if="errorMessage">{{
                  errorMessage
                }}</ion-text>
              </ion-item>
            </Field>
            <ion-item v-if="input.type === ActivityDataType.CHECKBOX">
              <ion-label>{{ input.name }}</ion-label>
              <ion-checkbox
                slot="end"
                :name="input.name"
                :required="input.required"
                @update:modelValue="logData[input.name] = $event ? '1' : '0'"
                :checked="logData[input.name] === '1'"
              ></ion-checkbox>
            </ion-item>

            <Field
              v-if="input.type === ActivityDataType.LIST"
              :name="input.name"
              v-slot="{ field, errorMessage, meta }"
              v-model="logData[input.name]"
              :rules="{ required: input.required }"
            >
              <ion-item :class="{required: input.required && !meta.valid }">
                <ion-label position="floating">{{ input.name }}</ion-label>
                <ion-select
                  v-bind="field"
                  :name="input.name"
                  v-model="logData[input.name]"
                  interface="popover"
                  :required="input.required"
                  type="number"
                >
                  <ion-select-option :value="option" v-for="(option, $index) in makeOptions(input.options)" :key="$index">{{option}}</ion-select-option>
                </ion-select>
                <ion-text color="danger" slot="end" v-if="errorMessage">{{
                  errorMessage
                }}</ion-text>
              </ion-item>
            </Field>
          </div>
        </ion-list>
        <ion-button
          expand="block"
          @click="createActivityLog()"
          :disabled="!meta.valid"
          >Ajouter</ion-button
        >
      </Form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonDatetime,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

import {
  PropType,
  defineComponent,
  ref,
  watchEffect,
} from "vue";
import { useStore } from "@/store";
import { Activity, ActivityDataType, LogData, Resource } from "@/store/models";

export default defineComponent({
  name: "CollecteData",
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const logData = ref<LogData>({timestampStart: new Date()});

    async function createActivityLog() {
      const data = logData.value;
      data.timestampEnd = new Date();
      data.duration = Math.ceil((data.timestampEnd.getTime() - data.timestampStart.getTime()) / 1000)
      console.log(data);
      await store.dispatch("createActivityLog", {
        resource: props.resource,
        activity: props.activity,
        data: logData,
      });
      modalController.dismiss();
    }

    watchEffect(() => {
      logData.value = (props.activity.data || []).reduce((obj: any, input) => {
            obj[input.name] = undefined;
            return obj;
          }, {
            timestampStart: new Date(),
          });
    });

    return {
      modalController,
      logData,
      createActivityLog,
      ActivityDataType,
      makeOptions(options: string) {
        return options.split(",").map(option => option.trim());
      }
    };
  },
  components: {
    Field,
    Form,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    IonDatetime,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
  },
});
</script>

