
import { trashOutline } from "ionicons/icons";
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  modalController,
  IonIcon,
} from "@ionic/vue";

import { PropType, defineComponent, reactive } from "vue";
import { useStore } from "@/store";
import { Log, ActivityDataType, LogData } from "@/store/models";

export default defineComponent({
  name: "CollecteData",
  props: {
    log: {
      type: Object as PropType<Log>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const localLog = reactive<Log>(
      Object.assign(
        {
          data: [] as LogData[],
        },
        JSON.parse(JSON.stringify(props.log))
      )
    );

    return {
      trashOutline,
      modalController,
      localLog,
      ActivityDataType,
      makeOptions(options: string) {
        return options.split(",").map((option) => option.trim());
      },
      removeKey(key: string) {
        if (localLog.data) {
          delete localLog.data[key];
        }
      },
      async addData() {
        const diversPrompt = await alertController.create({
          header: "Ajout de donnée",
          message: "Nom",
          inputs: [
            {
              name: "name",
              type: "text",
            },
          ],
          buttons: [
            {
              text: "Annuler",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Ajouter",
              handler: (data) => {
                if (!data.name) return;
                if (!localLog.data) {
                  localLog.data = {
                    timestampStart: new Date(),
                    timestampEnd: new Date(),
                    duration: 0,
                  };
                }
                localLog.data[data.name] = "";
              },
            },
          ],
        });
        diversPrompt.present();
      },
      async updateLog() {
        await store.dispatch("updateLogData", localLog);
        modalController.dismiss();
      },
    };
  },
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
  },
});
