
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useStore } from "@/store";
import {
  Activity,
  ActivityDataType,
  Resource,
  ActivityData,
} from "@/store/models";

export default defineComponent({
  name: "ActivityDataNew",
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
    editName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();

    let currentData: ActivityData = {
      name: "",
      type: ActivityDataType.NUMBER,
      required: false,
      options: "",
    };

    if (props.editName && props.activity.data) {
      const data = props.activity.data.find(
        (data) => data.name === props.editName
      );
      if (data) {
        currentData = JSON.parse(JSON.stringify(data));
      }
    }

    const activityData = reactive(currentData);

    const autofocusInput = ref<any>();

    const names = computed(() => {
      return (
        (props.activity.data &&
          props.activity.data
            .map((a) => a.name)
            .filter((n) => n !== props.editName)) ||
        []
      );
    });

    async function createActivityData() {
      await store.dispatch("addActivityData", {
        activity: props.activity,
        activityData,
        editName: props.editName,
      });
      modalController.dismiss();
    }

    onMounted(() => {
      setTimeout(() => {
        autofocusInput.value.$el.setFocus();
      }, 500);
    });

    return {
      modalController,
      activityData,
      createActivityData,
      names,
      autofocusInput,
      ActivityDataType,
    };
  },
  components: {
    IonButtons,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonText,
    Field,
    Form,
    IonRadio,
    IonRadioGroup,
    IonListHeader,
    IonCheckbox,
  },
});
