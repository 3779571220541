<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="modalController.dismiss()"
            >Annuler</ion-button
          >
        </ion-buttons>
        <ion-title> Ajouter une nouvelle ressource </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <Form v-slot="{ meta }" @submit="createResource()">
        <ion-list>
          <Field
            name="name"
            v-slot="{ field, errorMessage }"
            :rules="{ required: true, unique: names }"
            v-model="resourceName"
          >
            <ion-item>
              <ion-label position="floating">Nom de la ressource</ion-label>
              <ion-input
                v-bind="field"
                name="name"
                placeholder="Machine X, Personne Y, Poste de travail W, Secteur S, ..."
                v-model="resourceName"
                ref="autofocusInput"
                required
              ></ion-input>
              <ion-text color="danger" slot="end" v-if="errorMessage">{{
                errorMessage
              }}</ion-text>
            </ion-item>
          </Field>
        </ion-list>
        <ion-button
          expand="block"
          @click="createResource()"
          :disabled="!meta.valid"
          >Ajouter</ion-button
        >
      </Form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "ResourceNew",
  components: {
    IonButtons,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const resourceName = ref("");
    const autofocusInput = ref<any>();

    async function createResource() {
      await store.dispatch("createResource", resourceName.value);
      modalController.dismiss();
    }

    onMounted(() => {
      setTimeout(() => {
        autofocusInput.value.$el.setFocus();
      }, 500);
    });

    return {
      modalController,
      resourceName,
      createResource,
      names: store.getters.resourceNames,
      autofocusInput,
    };
  },
});
</script>
