
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useStore } from "@/store";
import { Resource } from "@/store/models";

export default defineComponent({
  name: "ActivityNew",
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const activity = reactive({
      name: "",
      type: "0",
    });

    const autofocusInput = ref<any>();

    const names = computed(() => {
      return props.resource.activities.map((a) => a.name);
    });

    async function createActivity() {
      await store.dispatch("addActivity", {
        resource: props.resource,
        activity,
      });
      modalController.dismiss();
    }

    onMounted(() => {
      setTimeout(() => {
        autofocusInput.value.$el.setFocus();
      }, 500);
    });

    return {
      modalController,
      activity,
      createActivity,
      names,
      autofocusInput,
    };
  },
  components: {
    IonButtons,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSelect,
    IonSelectOption,
    IonText,
    Field,
    Form,
  },
});
