<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="modalController.dismiss()"
            >Annuler</ion-button
          >
        </ion-buttons>
        <ion-title>
          Ajouter une nouvelle donnée à {{ activity.name }}</ion-title
        >
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding">
      <Form v-slot="{ meta }" @submit="createActivityData()">
        <ion-list>
          <Field
            name="name"
            v-slot="{ field, errorMessage }"
            :rules="{ required: true, unique: names }"
            v-model="activityData.name"
          >
            <ion-item>
              <ion-label position="floating">Nom de la donnée</ion-label>
              <ion-input
                v-bind="field"
                ref="autofocusInput"
                name="name"
                placeholder="Taille, Type, Identifiant, ..."
                v-model="activityData.name"
                required
              ></ion-input>
              <ion-text color="danger" slot="end" v-if="errorMessage">{{
                errorMessage
              }}</ion-text>
            </ion-item>
          </Field>

          <ion-list-header class="ion-padding-top">
            <ion-label>Type de la donnée</ion-label>
          </ion-list-header>

          <ion-radio-group v-model="activityData.type">
            <ion-item>
              <ion-label
                ><span class="data-type">123</span> Nombre (champ limité à des
                chiffres)</ion-label
              >
              <ion-radio
                slot="end"
                :value="ActivityDataType.NUMBER"
              ></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label
                ><span class="data-type">abc</span> Texte (champ texte
                libre)</ion-label
              >
              <ion-radio slot="end" :value="ActivityDataType.TEXT"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label><span class="data-type">jan</span> Date</ion-label>
              <ion-radio slot="end" :value="ActivityDataType.DATE"></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label
                ><span class="data-type"> ✓ </span> Case à cocher</ion-label
              >
              <ion-radio
                slot="end"
                :value="ActivityDataType.CHECKBOX"
              ></ion-radio>
            </ion-item>

            <ion-item>
              <ion-label
                ><span class="data-type">x|y</span> Liste (sélection d'une
                valeur)</ion-label
              >
              <ion-radio slot="end" :value="ActivityDataType.LIST"></ion-radio>
            </ion-item>
          </ion-radio-group>

          <ion-item v-if="activityData.type === ActivityDataType.LIST">
            <ion-label position="stacked">Valeurs de la liste</ion-label>
            <ion-input
              type="text"
              name="options"
              placeholder="Option1,Option2 (séparées par des virgules)"
              v-model="activityData.options"
            ></ion-input>
          </ion-item>

          <ion-list-header class="ion-padding-top">
            <ion-label>Donnée obligatoire</ion-label>
          </ion-list-header>

          <ion-item>
            <ion-label>Oui, valeur requise</ion-label>
            <ion-checkbox
              slot="end"
              v-model="activityData.required"
            ></ion-checkbox>
          </ion-item>
        </ion-list>
        <ion-button
          expand="block"
          @click="createActivityData()"
          :disabled="!meta.valid"
          >{{ editName ?  'Modifier' : 'Ajouter'}}</ion-button
        >
      </Form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useStore } from "@/store";
import {
  Activity,
  ActivityDataType,
  Resource,
  ActivityData,
} from "@/store/models";

export default defineComponent({
  name: "ActivityDataNew",
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
    editName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();

    let currentData: ActivityData = {
      name: "",
      type: ActivityDataType.NUMBER,
      required: false,
      options: "",
    };

    if (props.editName && props.activity.data) {
      const data = props.activity.data.find(
        (data) => data.name === props.editName
      );
      if (data) {
        currentData = JSON.parse(JSON.stringify(data));
      }
    }

    const activityData = reactive(currentData);

    const autofocusInput = ref<any>();

    const names = computed(() => {
      return (
        (props.activity.data &&
          props.activity.data
            .map((a) => a.name)
            .filter((n) => n !== props.editName)) ||
        []
      );
    });

    async function createActivityData() {
      await store.dispatch("addActivityData", {
        activity: props.activity,
        activityData,
        editName: props.editName,
      });
      modalController.dismiss();
    }

    onMounted(() => {
      setTimeout(() => {
        autofocusInput.value.$el.setFocus();
      }, 500);
    });

    return {
      modalController,
      activityData,
      createActivityData,
      names,
      autofocusInput,
      ActivityDataType,
    };
  },
  components: {
    IonButtons,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonText,
    Field,
    Form,
    IonRadio,
    IonRadioGroup,
    IonListHeader,
    IonCheckbox,
  },
});
</script>
