import { alertController, modalController } from "@ionic/vue";
import ResourceNew from "@/views/modals/ResourceNew.vue";
import ActivityNew from "@/views/modals/ActivityNew.vue";
import ActivityDataNew from "@/views/modals/ActivityDataNew.vue";
import CollecteData from "@/views/modals/CollecteData.vue";
import EditLogData from "@/views/modals/EditLogData.vue";
import UserLogin from "@/views/modals/UserLogin.vue";
import { store } from "@/store";
import { Activity, ActivityData, Resource, Log } from "@/store/models";

export async function showAddResource() {
  const modal = await modalController.create({
    component: ResourceNew,
  });
  return modal.present();
}

export async function showAddActivity(resource: Resource) {
  const modal = await modalController.create({
    component: ActivityNew,
    componentProps: {
      resource,
    },
  });
  return modal.present();
}

export async function showAddEditActivityData(
  resource: Resource,
  activity: Activity,
  editName?: string
) {
  const modal = await modalController.create({
    component: ActivityDataNew,
    componentProps: {
      resource,
      activity,
      editName,
    },
  });
  return modal.present();
}

export async function showUserLogin() {
  const modal = await modalController.create({
    component: UserLogin,
    backdropDismiss: false,
    keyboardClose: false,
    swipeToClose: false,
  });
  return modal.present();
}

export async function showCollecteActivityData(
  resource: Resource,
  activity: Activity
) {
  const modal = await modalController.create({
    component: CollecteData,
    componentProps: {
      resource,
      activity,
    },
  });
  return modal.present();
}

export async function showEditLogData(log: Log) {
  const modal = await modalController.create({
    component: EditLogData,
    componentProps: {
      log,
    },
  });
  return modal.present();
}
export async function deleteResourceConfirm(resource: Resource) {
  const alert = await alertController.create({
    header: "Confirmer!",
    message: `Supprimer la ressource ${resource.name}?`,
    buttons: [
      {
        text: "Annuler",
        role: "cancel",
      },
      {
        text: "Supprimer",
        handler: () => {
          store.dispatch("deleteResource", resource);
        },
      },
    ],
  });
  return alert.present();
}

export async function deleteActivityConfirm(
  resource: Resource,
  activity: Activity
) {
  const alert = await alertController.create({
    header: "Confirmer!",
    message: `Supprimer l'activité ${activity.name}?`,
    buttons: [
      {
        text: "Annuler",
        role: "cancel",
      },
      {
        text: "Supprimer",
        handler: () => {
          store.dispatch("deleteActivity", {
            resource,
            activity,
          });
        },
      },
    ],
  });
  return alert.present();
}

export async function deleteActivityDataConfirm(
  activity: Activity,
  activityData: ActivityData
) {
  const alert = await alertController.create({
    header: "Confirmer!",
    message: `Supprimer la donnée ${activityData.name}?`,
    buttons: [
      {
        text: "Annuler",
        role: "cancel",
      },
      {
        text: "Supprimer",
        handler: () => {
          if (!activity.data) return;
          store.dispatch("updateActivityPartial", {
            activity,
            data: {
              data: activity.data.filter((d) => d.name !== activityData.name),
            },
          });
        },
      },
    ],
  });
  return alert.present();
}

export async function showDiversPrompt(resource: Resource) {
  const diversPrompt = await alertController.create({
    header: "Divers",
    message: "Entrer le nom de l'activité",
    inputs: [
      {
        name: "name",
        type: "text",
      },
    ],
    buttons: [
      {
        text: "Annuler",
        role: "cancel",
        cssClass: "secondary",
      },
      {
        text: "Valider",
        handler: (data) => {
          if (!data.name) return;
          store.dispatch("createActivityLog", {
            resource,
            activity: {
              name: data.name,
              type: "1",
            },
            data: null,
          });
        },
      },
    ],
  });
  return diversPrompt.present();
}
