<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-searchbar
            placeholder="Ressources"
            v-model="query"
          ></ion-searchbar>
          <ion-list>
            <ion-menu-toggle auto-hide="false">
              <ion-reorder-group
                :disabled="!isResourceReorder"
                id="inbox-list"
                @ionItemReorder="doReorder($event)"
              >
                <ion-item
                  v-for="resource in filteredResources"
                  :key="resource.id"
                  router-direction="root"
                  :router-link="`/resource/${resource.id}`"
                  detail="false"
                  class="hydrated ion-activatable"
                  :class="{ selected: isSelected(`/resource/${resource.id}`) }"
                >
                  <ion-label>{{ resource.name }}</ion-label>
                  <ion-reorder slot="end"></ion-reorder>
                </ion-item>
              </ion-reorder-group>
            </ion-menu-toggle>
            <ion-item lines="none">
              <ion-icon
                slot="start"
                :ios="reorderThreeOutline"
                :md="reorderThreeSharp"
              ></ion-icon>
              <ion-label>Trier les resources</ion-label>
              <ion-toggle v-model="isResourceReorder"></ion-toggle>
            </ion-item>
          </ion-list>

          <ion-list id="actions-list">
            <ion-list-header>Actions</ion-list-header>
            <ion-item
              lines="none"
              class="ion-activatable"
              @click="showAddResource()"
            >
              <ion-icon
                slot="start"
                :ios="addOutline"
                :md="addSharp"
              ></ion-icon>
              <ion-label>Ajouter une ressource</ion-label>
            </ion-item>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                lines="none"
                router-link="/help"
                class="ion-activatable"
                :class="{ selected: isSelected('/help') }"
              >
                <ion-icon
                  slot="start"
                  :ios="helpOutline"
                  :md="helpSharp"
                ></ion-icon>
                <ion-label>Aide</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                lines="none"
                router-link="/data"
                class="ion-activatable"
                :class="{ selected: isSelected('/data') }"
              >
                <ion-icon
                  slot="start"
                  :ios="serverOutline"
                  :md="serverSharp"
                ></ion-icon>
                <ion-label>Données</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                lines="none"
                router-link="/options"
                class="ion-activatable"
                :class="{ selected: isSelected('/options') }"
              >
                <ion-icon
                  slot="start"
                  :ios="settingsOutline"
                  :md="settingsSharp"
                ></ion-icon>
                <ion-label>Options</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-item
              lines="none"
              v-if="!$store.state.user.locked && $store.state.user.token"
              class="ion-activatable"
              @click="logout()"
            >
              <ion-icon
                slot="start"
                :ios="logOutOutline"
                :md="logOutSharp"
              ></ion-icon>
              <ion-label>Déconnexion</ion-label>
            </ion-item>
          </ion-list>
          <p class="ion-text-center">
            <a href="https://xtenso.com/" target="about:blank"
              ><img class="logo" src="assets/icon/logo_xtenso.png"
            /></a>
          </p>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonReorder,
  IonReorderGroup,
  IonRouterOutlet,
  IonSearchbar,
  IonSplitPane,
  IonToggle,
} from "@ionic/vue";
import { computed, defineComponent, inject, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "./store";
import {
  addOutline,
  addSharp,
  archiveOutline,
  archiveSharp,
  serverOutline,
  serverSharp,
  heartOutline,
  heartSharp,
  helpOutline,
  helpSharp,
  logOutOutline,
  logOutSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  reorderThreeOutline,
  reorderThreeSharp,
  settingsOutline,
  settingsSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
} from "ionicons/icons";

import { showAddResource } from "./services/modals";

export default defineComponent({
  name: "App",
  components: {
    IonReorder,
    IonSearchbar,
    IonReorderGroup,
    IonToggle,
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    // get internal ionic router to do animated navigation
    const ionRouter = inject("navManager") as any;
    store.commit("setIonicRouter", ionRouter);

    const selectedIndex = ref(0);
    const isResourceReorder = ref(false);
    const query = ref("");

    const filteredResources = computed(() => {
      return store.state.resources.filter((r) => {
        const filter = query.value.toLowerCase();
        // show all if reorder to no loose filtered resources
        return r.name.toLowerCase().includes(filter) || isResourceReorder.value;
      });
    });

    function isSelected(url: string) {
      return route.path.startsWith(url) ? "selected" : "";
    }

    function doReorder(event: CustomEvent) {
      const newList = event.detail.complete(filteredResources.value);
      store.dispatch("setResources", newList);
    }

    function logout() {
      store.dispatch("clearUser")
    }

    return {
      selectedIndex,
      query,
      filteredResources,
      doReorder,
      archiveOutline,
      archiveSharp,
      addOutline,
      addSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      helpOutline,
      helpSharp,
      serverOutline,
      serverSharp,
      settingsOutline,
      settingsSharp,
      logOutOutline,
      logOutSharp,
      reorderThreeOutline,
      reorderThreeSharp,
      isResourceReorder,
      isSelected,
      showAddResource,
      logout,
    };
  },
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list,
ion-menu.md ion-list#actions-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#actions-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>