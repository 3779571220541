import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: () => import ('../views/Home.vue')
  },
  {
    path: '/resource/:id',
    component: () => import ('../views/Resource.vue')
  },
  {
    path: '/resource/:id/:activityIndex',
    component: () => import ('../views/Activity.vue')
  },
  {
    path: '/stats/:id',
    component: () => import ('../views/ResourceStats.vue')
  },
  {
    path: '/help',
    component: () => import ('../views/Help.vue')
  },
  {
    path: '/options',
    component: () => import ('../views/Options.vue')
  },
  {
    path: '/data',
    component: () => import ('../views/Data.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
