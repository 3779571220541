
import { ref, watchEffect } from "vue";
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import { Field, Form } from "vee-validate";

import { defineComponent } from "vue";

import { API_PLATFORM, useStore } from "../../store";

export default defineComponent({
  name: "UserLogin",
  setup() {
    const store = useStore();
    const password = ref("");
    const loginError = ref("");

    const email = ref("");
    const group = ref("");
    const company = ref("");

    watchEffect(() => {
      if (store.state.user.email) {
        email.value = store.state.user.email;
      }
      if (store.state.user.group) {
        group.value = store.state.user.group;
      }
      if (store.state.user.company) {
        company.value = store.state.user.company;
      }
    });

    function doLogin() {
      store
        .dispatch("doLogin", {
          email: email.value,
          password: password.value,
        })
        .then(() => {
          loginError.value = "";
        })
        .catch(() => {
          loginError.value = "Identifiants incorrects";
        });
    }

    async function setConfig() {
      await store.dispatch("setConfig", {
        company: company.value,
        group: group.value,
      });
      modalController.dismiss();
    }

    return {
      modalController,
      API_PLATFORM,
      email,
      password,
      group,
      company,
      loginError,
      doLogin,
      setConfig,
      noop() {
        //nothing
      },
    };
  },
  components: {
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonCol,
    IonRow,
    Field,
    Form,
  },
});
