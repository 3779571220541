
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonReorder,
  IonReorderGroup,
  IonRouterOutlet,
  IonSearchbar,
  IonSplitPane,
  IonToggle,
} from "@ionic/vue";
import { computed, defineComponent, inject, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "./store";
import {
  addOutline,
  addSharp,
  archiveOutline,
  archiveSharp,
  serverOutline,
  serverSharp,
  heartOutline,
  heartSharp,
  helpOutline,
  helpSharp,
  logOutOutline,
  logOutSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  reorderThreeOutline,
  reorderThreeSharp,
  settingsOutline,
  settingsSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
} from "ionicons/icons";

import { showAddResource } from "./services/modals";

export default defineComponent({
  name: "App",
  components: {
    IonReorder,
    IonSearchbar,
    IonReorderGroup,
    IonToggle,
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    // get internal ionic router to do animated navigation
    const ionRouter = inject("navManager") as any;
    store.commit("setIonicRouter", ionRouter);

    const selectedIndex = ref(0);
    const isResourceReorder = ref(false);
    const query = ref("");

    const filteredResources = computed(() => {
      return store.state.resources.filter((r) => {
        const filter = query.value.toLowerCase();
        // show all if reorder to no loose filtered resources
        return r.name.toLowerCase().includes(filter) || isResourceReorder.value;
      });
    });

    function isSelected(url: string) {
      return route.path.startsWith(url) ? "selected" : "";
    }

    function doReorder(event: CustomEvent) {
      const newList = event.detail.complete(filteredResources.value);
      store.dispatch("setResources", newList);
    }

    function logout() {
      store.dispatch("clearUser")
    }

    return {
      selectedIndex,
      query,
      filteredResources,
      doReorder,
      archiveOutline,
      archiveSharp,
      addOutline,
      addSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      helpOutline,
      helpSharp,
      serverOutline,
      serverSharp,
      settingsOutline,
      settingsSharp,
      logOutOutline,
      logOutSharp,
      reorderThreeOutline,
      reorderThreeSharp,
      isResourceReorder,
      isSelected,
      showAddResource,
      logout,
    };
  },
});
