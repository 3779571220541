export const SET_USER = "SET_USER";
export const SET_USER_PARTIAL = "SET_USER_PARTIAL";
export const ADD_RESOURCE = "ADD_RESOURCE";
export const ADD_ACTIVITYDATA = "ADD_ACTIVITYDATA";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const SET_RESOURCES = "SET_RESOURCES";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_PARTIAL = "UPDATE_ACTIVITY_PARTIAL";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const SET_CURRENT_RESOURCE_ID = "SET_CURRENT_RESOURCE_ID";
export const ADD_LOG = "ADD_LOG";
export const REPLACE_LOG = "REPLACE_LOG";
export const DELETE_LOG = "DELETE_LOG";
export const LOG_SYNCED = "LOG_SYNCED";
export const SET_LOGS = "SET_LOGS";
export const SET_LOGIN_CONFIG = "SET_LOGIN_CONFIG";
