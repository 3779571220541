<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="modalController.dismiss()"
            >Annuler</ion-button
          >
        </ion-buttons>
        <ion-title>
          Ajouter une nouvelle activité à {{ resource.name }}</ion-title
        >
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding" >
      <Form v-slot="{ meta }" @submit="createActivity()">
        <ion-list>
          <Field
            name="name"
            v-slot="{ field, errorMessage }"
            :rules="{ required: true, unique: names }"
            v-model="activity.name"
          >
            <ion-item>
              <ion-label position="floating">Nom de l'activité</ion-label>
              <ion-input
                v-bind="field"
                ref="autofocusInput"
                name="name"
                placeholder="Nom de l'activité (Accueil, Contrôle, ...)"
                v-model="activity.name"
                required
              ></ion-input>
              <ion-text color="danger" slot="end" v-if="errorMessage">{{
                errorMessage
              }}</ion-text>
            </ion-item>
          </Field>
          <ion-item>
            <div class="xs-activity-select" :class="'type-' + activity.type">
              <ion-label position="floating">Type</ion-label>
              <ion-select
                class="xs-activity-select"
                v-model="activity.type"
                interface="popover"
                :interface-options="{ cssClass: 'xs-activity-select' }"
                required
              >
                <ion-select-option value="0" class="type-0">
                </ion-select-option>
                <ion-select-option value="1" class="type-1" selected="selected">
                </ion-select-option>
                <ion-select-option value="2" class="type-2">
                </ion-select-option>
              </ion-select>
            </div>
          </ion-item>
        </ion-list>
        <ion-button
          expand="block"
          @click="createActivity()"
          :disabled="!meta.valid"
          >Ajouter</ion-button
        >
      </Form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import { useStore } from "@/store";
import { Resource } from "@/store/models";

export default defineComponent({
  name: "ActivityNew",
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const activity = reactive({
      name: "",
      type: "0",
    });

    const autofocusInput = ref<any>();

    const names = computed(() => {
      return props.resource.activities.map((a) => a.name);
    });

    async function createActivity() {
      await store.dispatch("addActivity", {
        resource: props.resource,
        activity,
      });
      modalController.dismiss();
    }

    onMounted(() => {
      setTimeout(() => {
        autofocusInput.value.$el.setFocus();
      }, 500);
    });

    return {
      modalController,
      activity,
      createActivity,
      names,
      autofocusInput,
    };
  },
  components: {
    IonButtons,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSelect,
    IonSelectOption,
    IonText,
    Field,
    Form,
  },
});
</script>

<style>
.xs-activity-select {
  width: 100%;
  max-width: 100%;
}
.xs-activity-select.type-0 {
  background-color: var(--xs-type-0-color) !important;
}

.xs-activity-select.type-1 {
  background-color: var(--xs-type-1-color) !important;
}

.xs-activity-select.type-2 {
  background-color: var(--xs-type-2-color) !important;
}
.xs-activity-select .select-interface-option.type-0 {
  --background: var(--xs-type-0-color);
}
.xs-activity-select .select-interface-option.type-1 {
  --background: var(--xs-type-1-color);
}
.xs-activity-select .select-interface-option.type-2 {
  --background: var(--xs-type-2-color);
}
.xs-activity-select .popover-content {
}
</style>
