
import { Field, Form } from "vee-validate";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "ResourceNew",
  components: {
    IonButtons,
    IonLabel,
    IonInput,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonButton,
    IonPage,
    IonTitle,
    IonToolbar,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const resourceName = ref("");
    const autofocusInput = ref<any>();

    async function createResource() {
      await store.dispatch("createResource", resourceName.value);
      modalController.dismiss();
    }

    onMounted(() => {
      setTimeout(() => {
        autofocusInput.value.$el.setFocus();
      }, 500);
    });

    return {
      modalController,
      resourceName,
      createResource,
      names: store.getters.resourceNames,
      autofocusInput,
    };
  },
});
