import { configure, defineRule } from "vee-validate";
import { localize, setLocale } from "@vee-validate/i18n";
import { email, required } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("unique", (value: string, list: string[]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }

  if (list.includes(value)) {
    return `Cette valeur existe déjà`;
  }

  return true;
});

configure({
  generateMessage: localize({
    fr: {
      messages: {
        required: "Ce champ est obligatoire",
        email: "Veuillez entrer une adresse email valide",
      },
    },
  }),
});
setLocale("fr");
